<script>
import MetisMenu from "metismenujs/dist/metismenujs";
import { mapState } from "vuex";

export default {
  locales: {
    pt: {
      'Go to store': 'Ir para a loja virtual',
      'Menu': 'Menu',
      'Dashboard': 'Painel',
      'Register': 'Cadastrar',
      'Courses': 'Cursos',
      'Investments': 'Investimentos',
      'My Contracts': 'Meus Contratos',
      'New Contract': 'Novo Contrato',
      'Store': 'Loja',
      'Products': 'Produtos',
      'Cart': 'Carrinho',
      'My Orders': 'Meus Pedidos',
      'Wallet': 'Carteira',
      'Deposit': 'Depositar',
      'Extract': 'Extrato',
      'Network': 'Rede',
      'Profit': 'Rendimento',
      'Activation': 'Ativação',
      'Balance': 'Saldo',
      'Voucher': 'Voucher',
      'Transfer': 'Transferir',
      'Pay': 'Pagar',
      'Withdrawal': 'Sacar',
      'Withdrawal History': 'Histórico de Saque',
      'Sponsored': 'Indicados',
      'Team': 'Equipe',
      'Binary': 'Binário',
      'Reports': 'Relatórios',
      'Bonus Referral': 'Bônus Indicação',
      'Bonus Team': 'Bônus Equipe',
      'Bonus Shopping': 'Bônus Compras',
      'Graduation': 'Graduação',
      'Support': 'Suporte',
      'Downloads': 'Downloads',
      'FAQ': 'FAQ',
      'Tickets': 'Tickets',
      'Points': 'Pontos',
      'Monthly Activation': 'Ativação Mensal'
    },
    es: {
      'Go to store': 'Ir a la tienda virtual',
      'Menu': 'Menu',
      'Dashboard': 'Panel',
      'Register': 'Registro',
      'Courses': 'Cursos',
      'Investments': 'Investimentos',
      'My Contracts': 'Mis Contratos',
      'New Contract': 'Nuevo Contrato',
      'Store': 'Tienda',
      'Products': 'Productos',
      'Cart': 'Cesta',
      'My Orders': 'Mis Pedidos',
      'Wallet': 'Cartera',
      'Deposit': 'Depositar',
      'Extract': 'Extracto',
      'Network': 'Red',
      'Profit': 'Rendimiento',
      'Activation': 'Activación',
      'Balance': 'Saldo',
      'Voucher': 'Cupón',
      'Transfer': 'Transferir',
      'Pay': 'Pagar',
      'Withdrawal': 'Retirada',
      'Withdrawal History': 'Historia de Retirada',
      'Sponsored': 'Patrocinados',
      'Team': 'Equipo',
      'Binary': 'Binario',
      'Reports': 'Informes',
      'Bonus Referral': 'Bonus Patrocinio',
      'Bonus Team': 'Bônus Equipo',
      'Bonus Shopping': 'Bônus Compras',
      'Graduation': 'Graduación',
      'Support': 'Soporte',
      'Downloads': 'Descargas',
      'FAQ': 'FAQ',
      'Tickets': 'Tickets',
      'Points': 'Puntos',
      'Monthly Activation': 'Activación Mensual'
    }
  },
  computed: {
    ...mapState({
      account: state => state.account
    })
  },
  mounted: function() {
    document.body.setAttribute("data-sidebar", "light");
    // eslint-disable-next-line no-unused-vars
    var menuRef = new MetisMenu("#side-menu");
    var links = document.getElementsByClassName("side-nav-link-ref");
    var matchingMenuItem = null;
    for (var i = 0; i < links.length; i++) {
      if (window.location.pathname === links[i].pathname) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;

      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add("mm-active");
        const parent2 = parent.parentElement.closest("ul");
        if (parent2 && parent2.id !== "side-menu") {
          parent2.classList.add("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add("mm-active");
            var childAnchor = parent3.querySelector(".has-arrow");
            var childDropdown = parent3.querySelector(".has-dropdown");
            if (childAnchor) childAnchor.classList.add("mm-active");
            if (childDropdown) childDropdown.classList.add("mm-active");

            const parent4 = parent3.parentElement;
            if (parent4) parent4.classList.add("mm-show");
            const parent5 = parent4.parentElement;
            if (parent5) parent5.classList.add("mm-active");
          }
        }
      }
    }
  },
  methods: {
    lightSidebar() {
      document.body.setAttribute("data-topbar", "dark");
      document.body.removeAttribute("data-sidebar");
      document.body.removeAttribute("data-layout-size", "boxed");
      document.body.removeAttribute("data-sidebar-size", "small");
      document.body.classList.remove("vertical-collpsed");
    },
    compactSidebar() {
      document.body.setAttribute("data-sidebar-size", "small");
      document.body.setAttribute("data-sidebar", "dark");
      document.body.removeAttribute("data-layout-size", "boxed");
      document.body.classList.remove("vertical-collpsed");
      document.body.removeAttribute("data-topbar", "dark");
    },
    iconSidebar() {
      document.body.setAttribute("data-keep-enlarged", "true");
      document.body.classList.add("vertical-collpsed");
      document.body.setAttribute("data-sidebar", "dark");
      document.body.removeAttribute("data-topbar", "dark");
      document.body.removeAttribute("data-layout-size", "boxed");
    },
    boxedLayout() {
      document.body.setAttribute("data-keep-enlarged", "true");
      document.body.classList.add("vertical-collpsed");
      document.body.setAttribute("data-layout-size", "boxed");
      document.body.removeAttribute("data-sidebar", "colored");
      document.body.setAttribute("data-sidebar", "dark");
      document.body.removeAttribute("data-topbar", "dark");
    },
    coloredSidebar() {
      document.body.setAttribute("data-sidebar", "colored");
      document.body.removeAttribute("data-layout-size", "boxed");
      document.body.removeAttribute("data-sidebar-size", "small");
      document.body.classList.remove("vertical-collpsed");
    }
  }
};
</script>

<template>
  <div id="sidebar-menu" class="pt-0">
    <ul id="side-menu" class="metismenu list-unstyled">
      <li class="menu-title">{{ t('Menu') }}</li>
      <li>
        <router-link tag="a" to="/" class="side-nav-link-ref">
          <i class="bx bx-home-circle"></i>
          <span class="notranslate">{{ t('Dashboard') }}</span>
        </router-link>
      </li>
      <li class="menu-title">{{ t('Investments') }}</li>
      <li>
        <router-link tag="a" to="/contracts" class="side-nav-link-ref">
          <i class="bx bx-line-chart"></i>
          <span class="notranslate">{{ t('My Contracts') }}</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/contracts/create" class="side-nav-link-ref">
          <i class="bx bx-plus"></i>
          <span class="notranslate">{{ t('New Contract') }}</span>
        </router-link>
      </li>
      <li class="d-none menu-title">{{ t('Support') }}</li>
      <li class="d-none">
        <router-link tag="a" to="/downloads" class="side-nav-link-ref">
          <i class="bx bx-download"></i>
          <span class="notranslate">{{ t('Downloads') }}</span>
        </router-link>
      </li>
      <li class="d-none">
        <router-link tag="a" to="/faq" class="side-nav-link-ref">
          <i class="bx bx-question-mark"></i>
          <span class="notranslate">{{ t('FAQ') }}</span>
        </router-link>
      </li>
      <li class="d-none">
        <router-link tag="a" to="/tickets" class="side-nav-link-ref">
          <i class="bx bx-conversation"></i>
          <span class="notranslate">{{ t('Tickets') }}</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>
